import React from "react"
import { Link } from "gatsby"

import "./css/index.scss"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import ImageCellphone from "../images/home/header_cellphone.png";
import ImageAppStore from "../images/home/header_appstore.png";
import ImageGooglePlay from "../images/home/header_googleplay.png";
import IconMoney from "../images/icons/icon_money.svg";
import IconCreditCard from "../images/icons/icon_credit-card.png";
import IconIntegration from "../images/icons/icon_integration.png";
import IconInovate from "../images/icons/icon_inovate.png";
import IconTime from "../images/icons/icon_time.svg";
import IconCustomers from "../images/icons/icon_customers.svg";

const IndexPage = () => (
  <Layout>
  <SEO title="QueroPedir" description="Faça pedidos na mesa do estabelecimento ou para delivery em um só aplicativo." keywords={[`delivery`, `entregas`, `restaurantes`, `padarias`, `farmácias`, `bares`, `petshop`, `adegas`, `pedidos`, `aplicativo`, `pedidos`, `quero pedir`, `auto atendimento`, `auto-atendimento`]} />
    <Header textHeader={false}>
      <div className="col-sm-4 col-md-6 d-none d-md-block text-center">
        <img className="home-header-cellphone animated fadeInLeft slow" src={ImageCellphone} alt="QueroPedir" title="QueroPedir" />
      </div>
      <div className="col-sm home-header-description animated fadeInRight">
        <h3 >O melhor aplicativo de <strong>marketplace</strong> para pedidos de <strong>entrega/retirada</strong> e <strong>auto-atendimento</strong></h3>
        <div className="home-header-download">
          <a href="https://apps.apple.com/us/app/queropedir/id1465384758" target="_BLANK">
            <img src={ImageAppStore} alt="Apple Store" title="Apple Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.queropedir" target="_BLANK">
            <img src={ImageGooglePlay} alt="Google Play" title="Google Play" />
          </a>
        </div>
      </div>
    </Header>
    <div className="container become-partner">
      <div className="row text-center">
        <h2>Seja um <strong>Parceiro</strong></h2>
      </div>
      <div className="row text-center">
        <h4>O aplicativo <strong>QueroPedir</strong> nasceu para facilitar<br className="d-none d-md-block"/> a maneira de receber pedidos tanto para delivery quanto para auto-atendimento.</h4>
      </div>
      <div className="row">
        <div className="col-sm-4 text-center">
          <img src={IconMoney} alt="aumente suas vendas" title="aumente suas vendas" className="icon" />
          <h5><strong>aumente</strong> suas vendas</h5>
          <p>tenha um canal a mais de vendas, facilite os pedidos e crie promoções</p>
        </div>
        <div className="col-sm-4 text-center">
          <img src={IconTime} alt="agilize seu atendimento" title="agilize seu atendimento" className="icon" />
          <h5><strong>agilize</strong> seu atendimento</h5>
          <p>não perca pedidos por falta<br className="hidden-md-down"/> de atendimento mesmo nos dias mais cheios</p>
        </div>
        <div className="col-sm-4 text-center">
          <img src={IconCreditCard} alt="informações de suas vendas" title="informações de suas vendas" className="icon" />
          <h5>informações de suas <strong>vendas</strong></h5>
          <p>tenha um resumo de pedidos e pagamento com um painel detalhado</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4 text-center">
          <img src={IconCustomers} alt="gerencie de forma simples" title="gerencie de forma simples" className="icon" />
          <h5><strong>gerencie</strong> de forma simples</h5>
          <p>controle seus pedidos e gerencie os produtos, catálogo e promoções</p>
        </div>
        <div className="col-sm-4 text-center">
          <img src={IconInovate} alt="inove o recebimento de pedidos" title="inove o recebimento de pedidos" className="icon" />
          <h5><strong>inove</strong> o recebimento de pedidos</h5>
          <p>receba pedidos no local com a leitura de um QR Code</p>
        </div>
        <div className="col-sm-4 text-center">
          <img src={IconIntegration} alt="integre suas informações" title="integre suas informações" className="icon" />
          <h5><strong>integre</strong> suas informações</h5>
          <p>mantemos unificado as informações com a integração do seu sistema</p>
        </div>
      </div>
      <div className="row more-info">
        <Link to="/parceiros"><button>SAIBA MAIS</button></Link>
      </div>
    </div>
  </Layout>
)

export default IndexPage
